import React from 'react';
import { createRoot } from 'react-dom/client'; // 從 react-dom/client 引入 createRoot
import { HashRouter } from 'react-router-dom';
import './scss/volt.scss';
import 'react-datetime/css/react-datetime.css';
import HomePage from './HomePage';
import ScrollToTop from './ScrollToTop';
import { ChatProvider } from './api/context';

// 使用 createRoot 取代 ReactDOM.render
createRoot(document.getElementById('root')).render(
  <ChatProvider>
    <HashRouter>
      <ScrollToTop />
      <HomePage />
    </HashRouter>
  </ChatProvider>
);
